@import "./reset.scss";

@font-face {
  font-family: "Cascadia Code";
  src: url("../fonts/Cascadia.ttf");
}

html {
  height: 100%;
}
body {
  color: #fff;
  font-family: monospace;
  background-image: url(../images/tile.png);
  background-size: cover;
  box-sizing: border-box;
  display: grid;
  height: 100%;
  grid-template-rows: 72fr 2fr 7fr;
}
a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: inherit;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;

  &:hover {
    background: rgba(127, 127, 127, 0.2);
  }
  &:focus {
    outline: 2px solid #ffaa00;
  }
  &:active {
    background: rgba(0, 0, 0, 0.2);
  }
}

header,
aside {
  mix-blend-mode: exclusion;
  font-family: "Major Mono Display", monospace;
  text-transform: uppercase;
  align-self: center;
  text-align: left;
  font-size: 14vmin;
  max-width: 7em;
  margin-left: 7vw;
  margin-right: 7vw;
}

aside {
  position: absolute;
  top: 20vh;
  right: 0;
  max-width: 70vmin;
  font-size: 8vmin;
  text-align: right;
  opacity: 0.77;
  text-transform: lowercase;
}

@media (max-width: 640px) {
  header {
    font-size: 18vmin;
  }
}

main.panel {
  font-family: "Cascadia Code" monospace;
  border-left-width: 0;
  border-right-width: 0;
  flex-wrap: wrap;
  .panel {
    margin: 2vh;
    min-width: 7em;
    width: 14vw;
  }
}

.panel {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-blend-mode: exclusion;
  background: rgba(27, 27, 27, 0.4);
  padding: 2vh;
  border-radius: 1px;
  border: 1px solid rgba(177, 177, 177, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2), 0 1px 7px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

main.panel {
  position: relative;
  &:before {
    content: "";
    background: url(../images/noise-dot.gif);
    opacity: 0.02;
    display: block;
    float: left;
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: 200px 200px;
    image-rendering: pixelated;
  }
}

body:after {
  content: "";
  background: url(../images/noise-static.gif), url(../images/noise-dot.gif);
  opacity: 0.04;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-size: 200px 200px;
  image-rendering: pixelated;
  background-blend-mode: exclusion;
}
